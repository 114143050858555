import React from 'react'
import { Helmet } from 'react-helmet'
import { schema } from '../../lib/helpers/seo'

const FaqSchema = ({ accordion }) => {

  if (!accordion) return null

  const { entity: { fieldAccordionItem } } = accordion
  const renderFaqs = () => {
    const mainEntity = fieldAccordionItem.map(({ entity: { fieldAccordionHeader: name, fieldAccordionText: { processed: text } } }) => ({
      "@type": "Question",
      name,
      "acceptedAnswer": {
        "@type": "Answer",
        text
      }
    }))

    return schema("FAQPage", { mainEntity })
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {renderFaqs()}
      </script>
    </Helmet>
  )
}

export default FaqSchema